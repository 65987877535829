import _lexer from "./lib/lexer";
import _parser from "./lib/parser";
import _stringify from "./lib/stringify";
var exports = {};
exports = {
  lex: _lexer,
  parse: _parser,
  stringify: _stringify
};
export default exports;
export const lex = exports.lex;